
import CloudFun, {
  defineComponent,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: "標籤",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "標籤清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "Ordinal", order: 0 });
            return model.dispatch("tag/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("tag/query") : undefined,
        save: model ? params => model.dispatch("tag/save", params) : undefined
      },
      modalConfig: { showFooter: true }
      //   treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
